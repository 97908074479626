import { useState } from 'react';
import { Box, Stack, keyframes } from '@mui/material';
import NuiIcon from '../../components/NuiIcon';
import NuiTypography from '../../components/NuiTypography';
import NuiCircularProgress from '../NuiCircularProgress';
import { globals } from '../../theme';

const animTrack = keyframes`
0% {
  transform: translateY(-0.5rem);
  opacity: 0;
}
100% {
  transform: translateX(0);
  opacity: 1;
}`;

const animArtworkPlay = keyframes`
0% {
  transform: rotate(0deg);
}
100% {
  transform: rotate(360deg);
}`;

const animArtworkImg = keyframes`
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}`;

const animProgress = keyframes`
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}`;

interface TrackProps {
  index: number;
  data: any;
  active: boolean;
  buffering: boolean;
  progress: number;
  onClick: Function;
}

const Track: React.FC<TrackProps> = ({
  index,
  data,
  active,
  buffering,
  progress,
  onClick,
}) => {
  const [loading, setLoading] = useState(true);

  const animDelay = index * 60;
  const { id, title, artist, urls } = data;
  return (
    <Box
      sx={{
        userSelect: 'none',
        opacity: 1,
        transition: 'opacity 0.3s',
        '@media (hover: hover)': {
          '&:hover': {
            cursor: 'pointer',
            opacity: active ? 1 : 0.6,
            transition: 'opacity 0.3s',
          },
        },
      }}
      onClick={() => onClick(index, id)}>
      <Stack
        direction="row"
        spacing={4}
        alignItems="center"
        sx={{
          opacity: 0,
          animation: `${animTrack} 0.3s ${animDelay}ms linear forwards`,
        }}>
        {/* Artwork */}
        <Box
          sx={{
            width: globals.units(12),
            height: globals.units(12),
            borderRadius: active ? 999 : globals.units(1),
            backgroundColor: globals.colors.white5,
            border: active ? `2px solid ${globals.colors.white}` : null,
            overflow: 'hidden',
            animation:
              active && !buffering
                ? `${animArtworkPlay} 1.333s linear infinite`
                : null,
            img: {
              opacity: 0,
              animation: !loading
                ? `${animArtworkImg} 0.3s linear forwards`
                : null,
            },
          }}>
          <img
            alt={title}
            src={urls.artwork}
            onLoad={() => setLoading(false)}
            style={{ width: '100%', height: '100%' }}
          />
        </Box>

        {/* Text */}
        <Stack spacing={0.5} sx={{ flex: 1 }}>
          <NuiTypography
            variant="body-demi"
            color={active ? globals.colors.app.primary : globals.colors.white}
            sx={{
              display: '-webkit-box',
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              transition: 'color 0.15s',
            }}>
            {title}
          </NuiTypography>
          <NuiTypography variant="caption" color={globals.colors.white80}>
            {artist}
          </NuiTypography>
        </Stack>

        {/* Actions */}
        <Stack spacing={0.5}>
          <Box
            sx={{
              position: 'relative',
              width: 32,
              height: 32,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            {/* Buffering */}
            {buffering && (
              <NuiCircularProgress
                size={16}
                sx={{
                  position: 'absolute',
                  '&.MuiCircularProgress-colorPrimary': {
                    color: globals.colors.app.primary,
                  },
                  '& .MuiCircularProgress-svg': {
                    circle: {
                      strokeWidth: 5,
                    },
                  },
                }}
              />
            )}

            {/* Progress */}
            {active && (
              <NuiCircularProgress
                variant="determinate"
                value={progress * 100}
                size={24}
                sx={{
                  position: 'absolute',
                  opacity: 0,
                  animation: `${animProgress} 0.15s 0.15s linear forwards`,
                  '& .MuiCircularProgress-circle': {
                    transition:
                      progress === 0 ? 'none' : 'stroke-dashoffset 1s',
                  },
                }}
              />
            )}

            {/* Icon */}
            <NuiIcon
              name={active ? 'stop-circle-filled' : 'play-circle'}
              size={5}
              color={
                active ? globals.colors.app.primary : globals.colors.white20
              }
              sx={{
                position: 'relative',
                opacity: buffering ? 0 : 1,
                transition: 'opacity 0.3s',
              }}
            />
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Track;
