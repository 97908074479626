const LoadingPlanet = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" fill="none">
    <g clipPath="url(#a)">
      <path
        className="anim-fill"
        fill="#FF813A"
        d="M62.697 102.541c21.688 0 39.269-17.58 39.269-39.268 0-21.688-17.581-39.27-39.269-39.27s-39.269 17.582-39.269 39.27c0 21.687 17.582 39.268 39.269 39.268Z"
      />
      <mask
        id="b"
        width={79}
        height={79}
        x={23}
        y={24}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="M62.697 102.541c21.688 0 39.269-17.58 39.269-39.268 0-21.688-17.581-39.27-39.269-39.27s-39.269 17.582-39.269 39.27c0 21.687 17.582 39.268 39.269 39.268Z"
        />
      </mask>
      <g fill="#FD81F5" mask="url(#b)">
        <path d="M42.768 101.591c7.156-3.197 13.06-8.655 19.944-12.37 5.53-2.98 11.721-4.867 16.66-8.755 3.759-2.966 6.84-7.085 11.376-8.611 1.095-.375 2.506-.475 3.226.417.835 1.052.1 2.592-.72 3.644-4.046 5.126-10.166 8.035-15.509 11.793-9.749 6.869-17.121 16.791-27.158 23.227M101.217 52.732c-3.412 4.58-8.481 7.617-13.608 10.123-5.126 2.52-10.483 4.651-15.134 7.949C65.491 75.744 60.177 83.23 52.2 86.34c-.677.274-1.411.519-1.915 1.037-.504.519-.735 1.426-.26 1.987.504.59 1.44.49 2.204.303 15.436-3.744 26.078-18.547 41.241-23.3 3.096-.964 6.322-1.497 9.303-2.75 2.98-1.253 5.803-3.427 6.811-6.509M76.147 94.564c1.555.705 3.34-.087 4.81-.936a42.589 42.589 0 0 0 8.711-6.74c.375-.374.763-.82.72-1.353-.475-4.94-15.422 8.496-14.241 9.029ZM32.313 87.825c2.304-3.672 6.365-5.79 10.008-8.136 16.128-10.383 26.712-27.692 42.38-38.78.388-.273.82-.547 1.28-.504.62.058 1.066.706 1.11 1.325.043.62-.216 1.239-.49 1.8-4.478 9.533-12.931 16.445-20.722 23.53a260.498 260.498 0 0 0-30.83 33.408M81.129 26.697a139.476 139.476 0 0 1-22.176 23.702c-5.328 4.508-11.29 9.03-13.392 15.682-.173.547-.245 1.282.245 1.555.36.202.82.029 1.195-.158 5.558-2.794 9.705-7.69 13.953-12.226a166.707 166.707 0 0 1 29.708-25.085M21.139 67.19c7.546-1.988 12.7-8.713 18.994-13.306 4.075-2.981 8.726-5.127 12.7-8.223 3.874-3.024 7.157-6.984 11.707-8.841.49-.202 1.066-.36 1.527-.101.907.518.403 1.915-.26 2.721-4.16 5.199-10.08 8.64-14.932 13.19-10.584 9.894-15.97 24.769-27.72 33.25M65.663 21.484c-1.972 3.629-5.702 5.962-9.446 7.704-3.744 1.742-7.733 3.11-11.06 5.573-3.326 2.462-5.817 5.904-9.071 8.467-2.808 2.218-6.106 3.73-9.144 5.645-3.039 1.915-5.919 4.377-7.215 7.718-.532 1.383-.518 3.37.879 3.86.907.33 1.886-.202 2.693-.75 7.315-4.867 13.507-11.361 21.168-15.667 3.916-2.188 8.15-3.772 11.937-6.177 6.02-3.816 10.771-9.605 17.28-12.514 1.858-.835 3.903-1.454 5.314-2.937 1.41-1.484 1.756-4.22.086-5.372" />
      </g>
      <path
        fill="#2259FF"
        d="M113.097 32.313c-1.08-2.333-3.297-4.047-5.76-4.709-2.721-.72-5.429.043-7.92 1.138-3.485 1.54-6.84 3.513-10.051 5.702a39.495 39.495 0 0 1 6.854 8.352c1.858-1.195 3.716-2.362 5.688-3.37.778-.403.216.663-.144 1.21a37.246 37.246 0 0 1-1.08 1.584c-.129.173-.244.346-.374.533-.043.043-.101.115-.187.216-.764.907-1.527 1.8-2.319 2.664-.245.259-.49.533-.734.792-1.958 2.045-4.046 3.989-6.134 5.89A241.729 241.729 0 0 1 77.803 63.46c-.519.403-1.023.806-1.541 1.21a.626.626 0 0 0-.1.086c-.015 0-.03.029-.059.043-.316.245-.619.475-.935.72a258.558 258.558 0 0 1-10.483 7.546 247.348 247.348 0 0 1-14.56 9.201 241.691 241.691 0 0 1-15.335 8.252c-.087.043-.158.072-.245.115-1.152.561-2.304 1.123-3.456 1.67-.59.274-1.18.562-1.771.835-2.65 1.167-3.125 1.34-3.845 1.383-.273.014-.62-.173-.533-.447.202-.604.879-1.8 1.21-2.29.475-.69.965-1.353 1.469-2.03.187-.259.389-.518.576-.777.029-.043.057-.087.086-.115.087-.116.173-.23.274-.346.69-.864 1.396-1.728 2.102-2.563a38.843 38.843 0 0 1-5.184-10.224c-.418.46-.82.907-1.224 1.368-2.347 2.678-4.55 5.486-6.638 8.366-2.117 2.88-3.975 6.307-3.701 9.994.345 4.55 3.614 8.265 8.05 9.317 4.104.979 7.92-.274 11.65-1.944 3.513-1.57 6.969-3.27 10.382-5.04 2.275-1.181 4.521-2.39 6.753-3.63 11.362-6.306 22.162-13.535 32.443-21.498A255.127 255.127 0 0 0 97.905 60.35a257.825 257.825 0 0 0 3.6-3.327c3.615-3.441 7.027-7.113 9.691-11.361 2.578-4.119 4.09-8.741 1.93-13.378l-.029.029Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M13.88 17.049H114.12v93.902H13.88z" />
      </clipPath>
    </defs>
  </svg>
);
export default LoadingPlanet;
