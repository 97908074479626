import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NuiThemeProvider from './components/NuiThemeProvider';

import { useAnalytics } from './helpers/analyticsHelper';

import HomeScreen from './screens/Home';
import { theme } from './theme';

function App() {
  const { analytics } = useAnalytics();
  return (
    <div className="App">
      {/* Theme */}
      <NuiThemeProvider theme={theme}>
        {/* Analytics */}
        {analytics && (
          <BrowserRouter>
            {/* Router for navigation */}
            <Routes>
              {/* embed version for streaming apps */}
              <Route path="/embed" element={<HomeScreen />} />
              {/* Root */}
              <Route path="/" element={<HomeScreen />} />
            </Routes>
          </BrowserRouter>
        )}
      </NuiThemeProvider>
    </div>
  );
}

export default App;
