import { useState, useEffect, useRef, useCallback } from 'react';
import { DialogContent, Stack, Box, keyframes } from '@mui/material';

import { getText } from '../../helpers/textHelper';
import NuiDialog from '../NuiDialog';
import NuiTypography from '../NuiTypography';
import NuiLink from '../NuiLink';
import NuiButton from '../NuiButton';
import { globals } from '../../theme';

const animDevice = (y: number, delay: number) =>
  `${keyframes`
0% {
  opacity: 0;
  transform: translateY(${y}%);
}
50% {
  opacity: 1;
}
100% {
  opacity: 1;
  transform: translateY(0);
}
`} 2s ${delay}ms cubic-bezier(0, 0.55, 0.45, 1) forwards`;

const id = 'upsell';
const text = getText();
const dialogText = text.DIALOGS[id.toUpperCase()];

const Artwork: React.FC = () => {
  const count = useRef(0);
  const [loading, setLoading] = useState(true);

  const handleLoad = useCallback(() => {
    count.current++;
    if (count.current >= 3) {
      setLoading(false);
    }
  }, []);

  const animProps = loading
    ? {}
    : {
        '&#mobile-3': {
          animation: animDevice(20, 150),
        },
        '&#mobile-2': {
          animation: animDevice(20, 150),
        },
        '&#mobile-1': {
          animation: animDevice(10, 0),
        },
      };

  useEffect(() => {}, []);
  return (
    <Box
      sx={(theme) => ({
        position: 'relative',
        width: '100%',
        height: 240,
        margin: `${theme.spacing(2)} 0 ${theme.spacing(8)}`,
        display: 'flex',
        justifyContent: 'center',
        overflow: 'hidden',
        img: {
          opacity: 0,
          ...animProps,
        },
      })}
    >
      <img
        id="mobile-3"
        alt="Napster App"
        src={`${process.env.PUBLIC_URL}/img/mobile-3.png`}
        onLoad={handleLoad}
        style={{
          position: 'absolute',
          top: '25%',
          left: 0,
          width: '40%',
          height: 'auto',
        }}
      />
      <img
        id="mobile-2"
        alt="Napster App"
        src={`${process.env.PUBLIC_URL}/img/mobile-2.png`}
        onLoad={handleLoad}
        style={{
          position: 'absolute',
          top: '25%',
          right: 0,
          width: '40%',
          height: 'auto',
        }}
      />
      <img
        id="mobile-1"
        alt="Napster App"
        src={`${process.env.PUBLIC_URL}/img/mobile-1.png`}
        onLoad={handleLoad}
        style={{
          position: 'absolute',
          width: '50%',
          height: 'auto',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          bottom: 0,
          height: '25%',
          background: `linear-gradient(180deg, rgba(24, 29, 56, 0.00), ${globals.colors.bgBase})`,
        }}
      />
    </Box>
  );
};

const DialogConnect: React.FC<{ open: boolean; onAction: Function }> = ({
  open,
  onAction,
}) => {
  return (
    <NuiDialog
      open={open}
      onClose={() => onAction('close', id)}
      sx={{
        '.MuiDialog-paper': {
          background: globals.colors.bgBase,
        },
      }}
    >
      <DialogContent>
        <Artwork />
        <Stack
          direction="column"
          spacing={2}
          alignItems="center"
          justifyContent="center"
          marginBottom={2}
        >
          <NuiTypography variant="title-bold" gutterBottom>
            {dialogText.TITLE}
          </NuiTypography>
        </Stack>
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            marginBottom: 4,
          }}
        >
          <NuiTypography variant="body" textAlign="center">
            {dialogText.DESCRIPTION}
          </NuiTypography>
        </Box>
        <Stack
          direction="column"
          spacing={6}
          alignItems="center"
          justifyContent="center"
          marginBottom={2}
        >
          <NuiButton color="primary" onClick={() => onAction('signup', id)}>
            {dialogText.CTA}
          </NuiButton>
          <NuiTypography variant="body-demi" color={globals.colors.white}>
            {dialogText.SIGNIN_1}
            <NuiLink
              onClick={() => onAction('connect-show', id)}
              sx={{ cursor: 'pointer' }}
            >
              {dialogText.SIGNIN_2}
            </NuiLink>
          </NuiTypography>
        </Stack>
      </DialogContent>
    </NuiDialog>
  );
};

export default DialogConnect;
