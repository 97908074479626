import { isMobile } from 'react-device-detect';
import { globals } from '../theme';

const getType = (str: string): string => {
  if (str.startsWith('http://') || str.startsWith('https://')) {
    // if (
    //   str.startsWith('https://play.napster.com/playlist') ||
    //   str.startsWith('https://web.napster.com/playlist')
    // ) {
    //   return 'napster'
    // } else if (str.startsWith('https://open.spotify.com/playlist')) {
    //   return 'spotify'
    // } else if (str.startsWith('https://music.apple.com')) {
    //   return 'apple'
    // } else {
    return 'unsupported';
    // }
  } else {
    return 'prompt';
  }
};

const getRandomColor = (): string => {
  const cols: string[] = Object.values(globals.colors.app);
  return cols[Math.floor(Math.random() * cols.length)];
};

const openLink = (url?: string): void => {
  if (!url) return console.log('No url provided');
  // Deep link on mobile, new tab on desktop
  if (isMobile) {
    window.location.href = url;
  } else {
    window.open(url, '_blank');
  }
};

export { getType, getRandomColor, openLink };
