import { Box } from '@mui/material';
import { getText } from '../../helpers/textHelper';
import NuiIcon from '../NuiIcon';
import NuiButton from '../NuiButton';
import NuiIconButton from '../NuiIconButton';
import NuiCircularProgress from '../NuiCircularProgress';
import { globals } from '../../theme';
import { isEmbed } from '../../helpers/embedHelper';

interface LabsCardFooterProps {
  state: string;
  loading: boolean;
  artwork: {
    loading: boolean;
    data?: ArtworkTypes | null;
  };
  playing: boolean;
  onAction: Function;
}

const text = getText();

const LabsCardFooter: React.FC<LabsCardFooterProps> = ({
  state,
  loading,
  artwork,
  playing,
  onAction,
}) => {
  const embed = isEmbed();
  const isActive =
    state === 'playlist' || state === 'saving' || state === 'saved';
  const isSaved = state === 'saved';
  const isLoading = loading || artwork?.loading;

  if (!isActive) return null;
  return (
    <Box
      sx={(theme) => ({
        position: 'relative',
        width: '100%',
        padding: embed
          ? `${theme.spacing(4)} ${theme.spacing(2.5)} ${theme.spacing(10)}`
          : `${theme.spacing(4)} ${theme.spacing(2.5)}`,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        background: embed ? globals.colors.bgBase : null,
        borderTop: embed ? null : `${globals.colors.white} 2px solid`,
        boxShadow: embed ? `0px -8px 8px 0px ${globals.colors.black20}` : null,
      })}>
      {/* Rate */}
      <NuiIconButton
        icon="arrow-left"
        sx={{
          '&.MuiIconButton-root.MuiIconButton-sizeMedium svg': {
            width: 24,
            height: 24,
          },
        }}
        onClick={() => onAction('back')}
      />

      {/* CTA */}
      <Box
        sx={(theme) => ({
          flex: 1,
          height: theme.spacing(11),
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        })}>
        {isLoading ? (
          <NuiCircularProgress
            size={32}
            sx={{
              '&.MuiCircularProgress-colorPrimary': {
                color: globals.colors.app.primary,
              },
            }}
          />
        ) : (
          <NuiButton
            disabled={isLoading}
            startIcon={isSaved ? <NuiIcon name="tick-circle-active" /> : null}
            sx={
              isSaved
                ? {
                    '&.MuiButton-containedPrimary': {
                      backgroundColor: globals.colors.app.tertiary,
                      color: globals.colors.black,
                      svg: {
                        fill: globals.colors.black,
                      },
                    },
                  }
                : null
            }
            onClick={() => onAction('save')}>
            {isSaved ? text.CTA_VIEW : text.CTA_SAVE}
          </NuiButton>
        )}
      </Box>

      {/* Right */}
      <NuiIconButton
        icon={playing ? 'stop-circle-filled' : 'play-circle-filled'}
        sx={{
          '&.MuiIconButton-root.MuiIconButton-sizeMedium svg': {
            width: 24,
            height: 24,
            fill: globals.colors.app.primary,
          },
        }}
        onClick={() => onAction(playing ? 'pause' : 'play')}
      />
    </Box>
  );
};

export default LabsCardFooter;
