import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Container, Stack, keyframes } from '@mui/material';

import { logEvent } from '../../helpers/analyticsHelper';
import { getPlaylist, getArtwork, getTracks } from '../../helpers/dataHelper';
import { createPlaylist } from '../../helpers/napiHelper';
import { useAuth } from '../../helpers/authHelper';
import { getType, openLink } from '../../helpers/interactionHelper';
import {
  postMessage,
  isEmbed,
  getDevice,
  useEmbedAuth,
} from '../../helpers/embedHelper';
import { getText } from '../../helpers/textHelper';

import NuiTypography from '../../components/NuiTypography';
import NuiButton from '../../components/NuiButton';
import NuiLink from '../../components/NuiLink';
import Header from '../../components/Header';
import Prompt from '../../components/Prompt';
import {
  LabsCard,
  LabsCardHeader,
  LabsCardContent,
  LabsCardFooter,
} from '../../components/LabsCard';
import Hints from '../../components/Hints';
import Artwork from '../../components/Artwork';
import Track from '../../components/Track';
import Player from '../../components/Player';
import Loading from '../../components/Loading';
import Error from '../../components/Error';
import Background from '../../components/Background';
import Snackbar from '../../components/Snackbar';
import Rating from '../../components/Rating';
import Stars from '../../components/Stars';
import Meta from '../../components/Meta';
import {
  DialogIntro,
  DialogConnect,
  DialogDisconnect,
  DialogUpsell,
  DialogFeedback,
} from '../../components/Dialog';
import { globals } from '../../theme';

const logData: boolean = process.env.REACT_APP_LOG_DATA === 'true';
const generateArtwork: boolean = process.env.REACT_APP_ARTWORK === 'true';

const animIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(0.5rem);
  }
  75% {
    opacity: 1;
    transform: translateY(-0.125rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const animCta = keyframes`
  0% {
    background-color: ${globals.colors.app.primary};
  }
  25% {
    background-color: ${globals.colors.app.quaternary};
  }
  50% {
    background-color: ${globals.colors.app.tertiary};
  }
  70% {
    background-color: ${globals.colors.app.secondary};
  }
  100% {
    background-color: ${globals.colors.app.primary};
  }
`;

const animLink = (colors: string[]) =>
  `${keyframes`
  0% {
    color: ${colors[0]};
    text-decoration-color: ${colors[0]};
  }
  33% {
    color: ${colors[1]};
    text-decoration-color: ${colors[1]};
  }
  66% {
    color: ${colors[2]};
    text-decoration-color: ${colors[2]};
  }
  100% {
    color: ${colors[0]};
    text-decoration-color: ${colors[0]};
  }
`} 2s linear infinite`;

const initialStates: InitialStatesTypes = {
  loading: false,
  buffering: false,
  state: 'initial',
  prompt: '',
  dialogs: {
    intro: false,
    upsell: false,
    connect: false,
    disconnect: false,
    feedback: false,
  },
  snackbar: {
    open: false,
    id: null,
    action: null,
  },
  error: null,
  data: null,
  artwork: { loading: true },
  artworkExport: null,
  queue: null,
  playing: false,
  index: 0,
  progress: 0,
  rating: 0,
};

const text = getText();

const HomeScreen: React.FC = () => {
  const inputRef = useRef<any>(null);
  const loaded = useRef<boolean>(false);
  const [prompt, setPrompt] = useState(initialStates.prompt);
  const [state, setState] = useState(initialStates.state);
  const [loading, setLoading] = useState(initialStates.loading);
  const [buffering, setBuffering] = useState(initialStates.buffering);
  const [dialogs, setDialogs] = useState(initialStates.dialogs);
  const [snackbar, setSnackbar] = useState(initialStates.snackbar);
  const [error, setError] = useState(initialStates.error);
  const [playing, setPlaying] = useState(initialStates.playing);
  const [progress, setProgress] = useState(initialStates.progress);
  const [data, setData] = useState<any>(initialStates.data);
  const [artwork, setArtwork] = useState<any>(initialStates.artwork);
  const [artworkExport, setArtworkExport] = useState(
    initialStates.artworkExport,
  );
  const [index, setIndex] = useState(initialStates.index);
  const [queue, setQueue] = useState<any>(initialStates.queue);
  const [rating, setRating] = useState(initialStates.rating);
  const { auth, profile, getAuth, clearAuth } = useAuth();
  const { embedAuth, getEmbedAuth } = useEmbedAuth();

  const embed = isEmbed();
  const isIntro = state === 'initial' || state === 'prompt';
  const isGenerateCta = isIntro && prompt.length > 3;
  const isPlaylist =
    state === 'playlist' || state === 'saving' || state === 'saved';

  // Reset state
  const onReset = useCallback(() => {
    setState(initialStates.state);
    setLoading(initialStates.loading);
    setDialogs(initialStates.dialogs);
    setSnackbar(initialStates.snackbar);
    setError(initialStates.error);
    setPlaying(initialStates.playing);
    setProgress(initialStates.progress);
    setData(initialStates.data);
    setArtwork(initialStates.artwork);
    setArtworkExport(initialStates.artworkExport);
    setIndex(initialStates.index);
    setQueue(initialStates.queue);
    setRating(initialStates.rating);
  }, []);

  // Errors
  const handleError = useCallback(async (id: string) => {
    logEvent('error', { id });
    switch (id) {
      case 'generic':
      case 'maintenance':
      case 'highdemand':
      case 'notracks':
      case 'link':
        setState('error');
        setError(id);
        break;
      default:
        console.log(`Nothing for ${id}`);
        break;
    }
  }, []);
  const handleErrorAction = useCallback(async (type: string, id: string) => {
    switch (type) {
      case 'cta':
        switch (id) {
          case 'generic':
          case 'highdemand':
          case 'notracks':
          case 'link':
            window.location.reload();
            break;
          default:
            console.log(`Nothing for ${id}`);
            break;
        }
        break;
      default:
        console.log(`Nothing for ${type} from ${id}`);
        break;
    }
  }, []);

  // Snackbars
  const toggleSnackbar = useCallback(
    (open: boolean, id?: string, action?: string) => {
      setSnackbar({
        ...snackbar,
        open,
        id: id || snackbar.id,
        action: action || null,
      });
    },
    [snackbar],
  );

  // Ratings & Feedback
  const handleFeedbackSubmit = useCallback(
    (type: string, actionData: any) => {
      const isRating = type === 'rating';
      if (isRating) {
        const { rating } = actionData;
        setRating(rating);
        logEvent('rating-sent', {
          rating,
          data,
        });
      } else {
        const { feedback } = actionData;
        logEvent('feedback-sent', {
          rating,
          data,
          feedback,
        });
        toggleSnackbar(true, 'feedback-sent');
      }
    },
    [data, rating, toggleSnackbar],
  );

  // Prompt input
  const handleInput = useCallback(
    (val: string) => {
      setPrompt(val);
      // Valid prompt entered
      if (val.length > 3) {
        setState('prompt');
      }
      // Reset state
      if (state !== 'initial' && state !== 'prompt') {
        onReset();
      }
    },
    [state, onReset],
  );

  // Generate artwork synchroneously
  const getPlaylistArtwork = useCallback(async (prompt: string) => {
    if (!generateArtwork) return setArtwork({ loading: false });
    let start;
    if (logData) start = performance.now();
    logEvent('action', { id: 'artwork', prompt });
    const req = await getArtwork(prompt);
    if (start) {
      console.info(`getArtwork took ${performance.now() - start} ms to run`);
    }
    if (!req || !req.ok || !req.data) {
      console.error('Error getting artwork');
    }
    logEvent('artwork-generated', {
      prompt,
      data: {
        ok: req.ok,
        url: req.data?.url,
        colors: req.data?.colors,
      },
    });
    setArtwork({ loading: false, data: req.data });
  }, []);

  // Prompt submit (generate Playlist)
  const handleSubmit = useCallback(
    async (promptFromHint?: string) => {
      let newPrompt = promptFromHint || prompt;
      if (!newPrompt || newPrompt.length < 3) return;
      if (promptFromHint) setPrompt(promptFromHint);
      if (getType(prompt) === 'unsupported') return handleError('link');
      setData(initialStates.data);
      setIndex(initialStates.index);
      setQueue(initialStates.queue);
      setError(initialStates.error);
      setLoading(true);
      setState('generating');
      // Get artwork synchroneously
      getPlaylistArtwork(newPrompt);
      // Get Playlist, set title / description
      logEvent('action', { id: 'playlist', prompt: newPrompt });
      let start;
      if (logData) start = performance.now();
      const req = await getPlaylist(newPrompt);
      // Check for errors based on code
      if (!req || !req.ok || !req.data) {
        const { code } = req;
        switch (code) {
          case 424:
            handleError('highdemand');
            break;
          default:
            handleError('generic');
            break;
        }
        setLoading(initialStates.loading);
        return;
      }
      let newData = req?.data;
      // Set prompt from hint origin for analytics
      if (promptFromHint) newData.origin = 'prompt-hint';
      if (start) {
        console.info(`getPlaylist took ${performance.now() - start} ms to run`);
      }
      setData(newData);
      const isNapster = newData.origin === 'napster';
      // Get tracks if not Napster
      let startGetTracks;
      if (logData) startGetTracks = performance.now();
      if (isNapster) {
        newData.suggestions = newData.tracks ? [...newData.tracks] : [];
      } else {
        setLoading(true);
        setState('searching');
        newData = await getTracks(newData);
        if (!newData.ok || !newData.tracks) {
          handleError('generic');
          setLoading(initialStates.loading);
          return;
        }
      }
      // Log data
      if (startGetTracks) {
        console.info(
          `getTracks took ${performance.now() - startGetTracks} ms to run`,
        );
        console.log(JSON.stringify(newData));
        console.log(newData);
      }
      if (!newData.tracks || newData.tracks.length < 1) {
        return handleError('notracks');
      }
      logEvent('playlist-generated', { prompt: newPrompt, data: newData });
      setData(newData);
      setQueue(newData.tracks);
      setLoading(initialStates.loading);
      setState('playlist');
    },
    [prompt, getPlaylistArtwork, handleError],
  );

  // Dialogs
  const toggleDialog = useCallback(
    (id: string, open: boolean) => {
      logEvent('dialog', { id, open });
      setDialogs({ ...dialogs, [id]: open });
    },
    [dialogs],
  );

  // Save Playlist
  const handleSave = useCallback(
    async (newAuthFromConnect?: AuthTypes) => {
      // Get embed token if we don't have one
      if (embed && !embedAuth) {
        await getEmbedAuth();
        return;
      }
      let newAuth = newAuthFromConnect || auth;
      // Check auth
      if (!newAuth) {
        toggleDialog('upsell', true);
        return;
      }
      // Save playlist
      setLoading(true);
      setState('saving');
      const playlist = await createPlaylist(
        data,
        artworkExport,
        // If embed, use token in state instead of in cookies
        embed && newAuth ? newAuth : null,
      );
      if (!playlist.ok || !playlist.url) {
        handleError('generic');
        return;
      }
      logEvent('playlist-saved', { data, profile });
      setLoading(initialStates.loading);
      setState('saved');
      // Update data with url and display snackbar (if not embedded)
      setData({ ...data, url: playlist.url });
      !embed && toggleSnackbar(true, 'saved', 'link');
      // Message back to parent if in embed mode
      postMessage({ type: 'playlist', data: playlist });
    },
    [
      embed,
      embedAuth,
      getEmbedAuth,
      data,
      artworkExport,
      profile,
      auth,
      handleError,
      toggleDialog,
      toggleSnackbar,
    ],
  );

  // If embedded mode, wait for token response via messaging API
  useEffect(() => {
    if (!embed || !embedAuth) return;
    handleSave(embedAuth);
    // We only want to run this once or when the token changes
    // Adding the callback below will cause an infinite loop
    // eslint-disable-next-line
  }, [embed, embedAuth]);

  // Header / Footer actions
  const handleAction = useCallback(
    async (type: string | null, actionData?: any) => {
      logEvent('action', { type, ...actionData });
      switch (type) {
        case 'back':
          setPrompt(initialStates.prompt);
          onReset();
          if (inputRef.current) {
            inputRef.current.focus();
          }
          break;
        case 'save':
          const playlistUrl = data?.url;
          if (playlistUrl) {
            openLink(playlistUrl);
          } else {
            handleSave();
          }
          break;
        case 'link':
          const { url } = actionData;
          openLink(url);
          break;
        case 'app':
        case 'my-music':
        case 'settings':
          const isApp = type === 'app';
          openLink(`https://play.napster.com/${isApp ? '' : type}`);
          break;
        case 'play':
          setPlaying(true);
          postMessage({ type: 'playing' });
          break;
        case 'pause':
          setPlaying(false);
          postMessage({ type: 'paused' });
          break;
        case 'disconnect':
          toggleDialog('disconnect', true);
          break;
        case 'rating':
          handleFeedbackSubmit('rating', actionData);
          break;
        case 'feedback':
          toggleDialog('feedback', true);
          break;
        case 'quit':
          postMessage({ type: 'close' });
          break;
        default:
          console.log(`Nothing for ${type}`);
          break;
      }
    },
    [data, handleSave, toggleDialog, onReset, handleFeedbackSubmit],
  );

  const handleDialogAction = useCallback(
    async (type: string, id: string, actionData?: any) => {
      logEvent('dialog-action', { id, type });
      // console.log(type, id, data);
      switch (type) {
        case 'signup':
          window.open(
            'https://order.napster.com/checkout/playlistgpt',
            '_blank',
          );
          break;
        case 'forgotpass':
          window.open(
            'https://account.napster.com/myacct/forgotpassworddefault.html',
            '_blank',
          );
          break;
        case 'connect-show':
          toggleDialog('connect', true);
          break;
        case 'connect':
          const { email, password } = actionData;
          setLoading(true);
          const newAuth = await getAuth(email, password);
          if (!newAuth || !newAuth.ok || !newAuth.auth) {
            return setLoading(false);
          }
          toggleSnackbar(true, 'connected');
          setDialogs(initialStates.dialogs);
          setLoading(initialStates.loading);
          // After connect, save playlist
          handleSave(newAuth.auth);
          break;
        case 'disconnect':
          clearAuth();
          toggleDialog(id, false);
          break;
        case 'feedback':
          toggleDialog(id, false);
          handleFeedbackSubmit('feedback', actionData);
          break;
        case 'close':
          toggleDialog(id, false);
          break;
        default:
          console.log(`Nothing for ${type} from ${id}`);
          break;
      }
    },
    [
      handleFeedbackSubmit,
      getAuth,
      toggleDialog,
      toggleSnackbar,
      clearAuth,
      handleSave,
    ],
  );

  // Media controls
  const handleTrack = useCallback(
    (newIndex: number, id: string) => {
      logEvent('action', { id: 'track', track: id });
      if (!queue || queue.length < 0) return;
      // Found a track in queue
      if (newIndex > -1) {
        setIndex(newIndex);
        // Update play state
        const isCurrent = newIndex === index;
        if (isCurrent) {
          setPlaying(!playing);
          setBuffering(initialStates.buffering);
          navigator.mediaSession.playbackState = playing ? 'paused' : 'playing';
          postMessage({ type: playing ? 'paused' : 'playing' });
        } else {
          setPlaying(true);
          postMessage({ type: 'playing' });
          setBuffering(true);
          navigator.mediaSession.playbackState = 'playing';
        }
      }
    },
    [queue, index, playing],
  );
  const handlePlay = useCallback(() => {
    logEvent('action', { id: 'play' });
    setPlaying(true);
    postMessage({ type: 'playing' });
    navigator.mediaSession.playbackState = 'playing';
  }, []);
  const handlePause = useCallback(() => {
    logEvent('action', { id: 'pause' });
    setPlaying(false);
    postMessage({ type: 'paused' });
    navigator.mediaSession.playbackState = 'paused';
  }, []);
  const handleNext = useCallback(() => {
    if (!queue) return;
    if (index >= queue.length - 1) {
      setPlaying(false);
      postMessage({ type: 'paused' });
      return;
    }
    logEvent('event', { id: 'track-next' });
    setIndex(index + 1);
  }, [index, queue]);
  const handlePrevious = useCallback(() => {
    if (index < 1) return;
    logEvent('event', { id: 'track-previous' });
    setIndex(index - 1);
  }, [index]);
  const handleEnd = useCallback(() => {
    logEvent('event', { id: 'track-end' });
    handleNext();
  }, [handleNext]);

  // Update progress on index change
  useEffect(() => {
    if (!queue || queue.length < 1) return;
    setProgress(0);
  }, [queue, index]);

  // Embed mode initialisers
  useEffect(() => {
    if (!embed || loaded.current) return;
    loaded.current = true;
    // Set background colour
    const body = document.querySelector('body');
    if (!body) return;
    body.style.backgroundColor = globals.colors.bgBase;
    // Log device
    const device = getDevice();
    logEvent('embed', device);
    // Post loaded
    postMessage({ type: 'loaded' });
  }, [embed]);

  return (
    <Container
      sx={(theme) => ({
        padding: embed
          ? `${theme.spacing(isIntro ? 36 : 14)} 0 0`
          : `${theme.spacing(24)} ${theme.spacing(4)} ${theme.spacing(8)}`,
        minHeight: '90vh',
        maxHeight: embed ? '100vh' : null,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: embed ? null : 'center',
        alignItems: 'center',
        [theme.breakpoints.up('lg')]: {
          minHeight: '100vh',
        },
      })}>
      {/* Metadata */}
      <Meta data={data} />

      {/* Background */}
      {<Background state={state} />}

      {/* Card */}
      <LabsCard state={state}>
        {/* Header */}
        <LabsCardHeader badge={isIntro}>
          <Stack spacing={5} alignItems="center" width="100%">
            {isIntro && (
              <Stack spacing={3} alignItems="center" width="100%">
                <NuiTypography
                  variant="body"
                  textAlign="center"
                  color={globals.colors.white80}>
                  {text.INTRO_1}
                  <NuiLink
                    onClick={() => toggleDialog('intro', true)}
                    sx={{
                      cursor: 'pointer',
                      animation: animLink([
                        globals.colors.app.primary,
                        globals.colors.app.secondary,
                        globals.colors.app.quaternary,
                      ]),
                    }}>
                    {text.INTRO_2}
                  </NuiLink>
                  {text.INTRO_3}
                </NuiTypography>
                <NuiTypography
                  variant="title-small-bold"
                  textAlign="center"
                  color={globals.colors.white}>
                  {text.INTRO_4}
                </NuiTypography>
              </Stack>
            )}

            {/* Prompt */}
            <Stack
              spacing={4}
              flexDirection="column"
              justifyContent="center"
              width="100%">
              <Prompt
                ref={inputRef}
                state={state}
                loading={loading}
                prompt={prompt}
                onInput={handleInput}
                onSubmit={handleSubmit}
                onAction={handleAction}
              />
            </Stack>

            {/* CTA */}
            {isGenerateCta && (
              <NuiButton
                disabled={loading}
                onClick={() => handleSubmit()}
                sx={{
                  backgroundColor: globals.colors.app.primary,
                  color: globals.colors.black,
                  animation: `${animIn} 0.3s forwards, ${animCta} 6s infinite`,
                }}>
                {text.CTA_GENERATE}
              </NuiButton>
            )}
          </Stack>
        </LabsCardHeader>

        {/* Content */}
        <LabsCardContent state={state}>
          {/* Hints & 'Powered by' */}
          {isIntro && (
            <Stack spacing={6}>
              <Hints onHint={(hint: string) => handleSubmit(hint)} />{' '}
              <NuiTypography
                variant="caption-demi"
                textAlign="center"
                color={globals.colors.white60}>
                {text.INTRO_FOOTER}
              </NuiTypography>
            </Stack>
          )}

          {/* Loading */}
          {(state === 'generating' || state === 'searching') && <Loading />}

          {/* Error */}
          {error && <Error error={error} onAction={handleErrorAction} />}

          {/* Playlist */}
          {isPlaylist && (
            <Stack spacing={10}>
              {/* Header */}
              <Stack spacing={6} alignItems="center">
                {/* Artwork */}
                {generateArtwork && (
                  <Artwork
                    loading={artwork.loading}
                    artwork={artwork.data}
                    onArtwork={(base64?: string) => {
                      if (!base64) return;
                      setArtworkExport(base64);
                    }}
                  />
                )}
                {/* Text */}
                <Stack spacing={2} alignItems="center">
                  {/* Title */}
                  <NuiTypography variant="headline-bold" textAlign="center">
                    {data.title}
                  </NuiTypography>
                  {/* Description */}
                  {data.description && (
                    <NuiTypography
                      variant="body"
                      textAlign="center"
                      color={globals.colors.white80}>
                      {data.description}
                    </NuiTypography>
                  )}
                  {/* Rating */}
                  <Rating
                    state={state}
                    rating={rating}
                    onAction={handleAction}
                  />
                </Stack>
              </Stack>
              {/* Tracks */}
              {data?.tracks?.length > 0 && (
                <Stack spacing={7}>
                  {data.tracks.map((track: TrackTypes, trackIndex: number) => {
                    return (
                      <Track
                        key={trackIndex}
                        index={trackIndex}
                        data={track}
                        active={trackIndex === index && playing}
                        buffering={trackIndex === index && buffering}
                        progress={progress}
                        onClick={handleTrack}
                      />
                    );
                  })}
                </Stack>
              )}
            </Stack>
          )}
        </LabsCardContent>

        {/* Footer */}
        <LabsCardFooter
          state={state}
          loading={loading}
          artwork={artwork}
          playing={playing}
          onAction={handleAction}
        />
      </LabsCard>

      {/* Player */}
      <Player
        queue={queue}
        index={index}
        playing={playing}
        onEnd={handleEnd}
        onPlay={handlePlay}
        onPause={handlePause}
        onNext={handleNext}
        onPrevious={handlePrevious}
        onProgress={(val: number) => setProgress(val)}
        onBuffer={() => setBuffering(true)}
        onBufferEnd={() => setBuffering(false)}
      />

      {/* Header */}
      <Header
        beta
        embed={embed}
        state={state}
        auth={auth}
        profile={profile}
        onAction={handleAction}
      />

      {/* Dialogs */}
      <DialogIntro open={dialogs.intro} onAction={handleDialogAction} />
      <DialogUpsell open={dialogs.upsell} onAction={handleDialogAction} />
      <DialogConnect
        open={dialogs.connect}
        loading={loading}
        onAction={handleDialogAction}
      />
      <DialogDisconnect
        open={dialogs.disconnect}
        onAction={handleDialogAction}
      />
      <DialogFeedback
        open={dialogs.feedback}
        data={data}
        onAction={handleDialogAction}
      />

      {/* Snackbars */}
      <Snackbar
        open={snackbar.open}
        id={snackbar.id}
        action={snackbar.action}
        onAction={
          snackbar.action ? () => handleAction('link', { url: data.url }) : null
        }
        onClose={() => toggleSnackbar(false)}
      />

      {/* Stars */}
      {isPlaylist && <Stars />}
    </Container>
  );
};

export default HomeScreen;
