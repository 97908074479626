import axios from 'axios';
import playlistTest from './data/playlist-test.json';
import artworkTest from './data/artwork-test.json';
import tracksTest from './data/tracks-test.json';

const serverUrl = process.env.REACT_APP_SERVER_URL;
const apiKey: string | undefined = process.env.REACT_APP_API_KEY;
const testMode: boolean = process.env.REACT_APP_TEST_MODE === 'true';
const headers = {
  'Content-Type': 'application/json',
  'x-api-key': apiKey,
};

const getPlaylist = async (
  text: string,
): Promise<{ ok: boolean; code?: number; data?: PlaylistTypes }> => {
  if (!apiKey) return { ok: false };
  if (testMode) return { ok: true, data: playlistTest };
  try {
    const url = `${serverUrl}/playlist`;
    const body = { text };
    const res = await axios.post(url, body, { headers });
    if (res?.status === 200) {
      return { ok: true, data: res.data };
    } else {
      return { ok: false };
    }
  } catch (e: any) {
    console.error(e);
    const status = e?.response?.status;
    return { ok: false, code: status };
  }
};

const getArtwork = async (
  text: string,
): Promise<{ ok: boolean; code?: number; data?: ArtworkTypes }> => {
  if (!apiKey) return { ok: false };
  if (testMode) return { ok: true, data: artworkTest };
  try {
    const url = `${serverUrl}/artwork`;
    const body = { text };
    const res = await axios.post(url, body, { headers });
    if (res?.status === 200) {
      return { ok: true, data: res.data };
    } else {
      return { ok: false };
    }
  } catch (e: any) {
    console.error(e);
    const status = e?.response?.status;
    return { ok: false, code: status };
  }
};

const getTracks = async (playlist: PlaylistTypes): Promise<PlaylistTypes> => {
  if (!apiKey) return { ok: false };
  if (testMode) return { ...playlistTest, tracks: tracksTest };
  try {
    const url = `${serverUrl}/tracks`;
    const res = await axios.post(url, playlist, { headers });
    if (res?.status === 200) {
      return await res.data;
    } else {
      return { ok: false };
    }
  } catch (err) {
    console.error(err);
    return { ok: false };
  }
};

export { getPlaylist, getArtwork, getTracks };
