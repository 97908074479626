import { useState, useEffect } from 'react';
import { Box, Stack } from '@mui/material';

import { getText } from '../../helpers/textHelper';
import { shuffleArray } from '../../helpers/utils';
import LoadingArtwork from './LoadingArtwork';
import NuiTypography from '../NuiTypography';
import NuiLinearProgress from '../NuiLinearProgress/';
import { globals } from '../../theme';

const text = getText();
const loadingTimeSecs = 16;
const loadingTimeSteps = 100;
const loadingTimeInterval = (loadingTimeSecs * 1000) / loadingTimeSteps;

const artworks = shuffleArray([
  'boombox',
  'note',
  'keyboard',
  'planet',
  'vinyl',
]);
const messages = shuffleArray(text.LOADING_MESSAGES);
const messageCount = 3;

const Loading: React.FC = () => {
  const [progress, setProgress] = useState(0);
  const [artwork, setArtwork] = useState(artworks[0]);
  const [message, setMessage] = useState(messages[0]);

  // Incremement progress
  useEffect(() => {
    if (progress >= 1) return setProgress(1);
    const timeout = setTimeout(() => {
      const newProgress = progress + 1 / loadingTimeSteps;
      setProgress(newProgress);
    }, loadingTimeInterval);
    return () => clearTimeout(timeout);
  }, [progress]);

  // Update initial messages
  useEffect(() => {
    if (progress >= 1) return;
    const index = Math.floor(progress * messageCount);
    setArtwork(artworks[index]);
    setMessage(messages[index]);
  }, [progress]);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      {/* Graphic */}
      <LoadingArtwork artwork={artwork} />
      <Stack spacing={2} marginBottom={12}>
        {/* Title */}
        <NuiTypography variant="headline-bold" textAlign="center">
          {message.TITLE}
        </NuiTypography>
        {/* Description */}
        <NuiTypography
          variant="body"
          textAlign="center"
          color={globals.colors.white80}>
          {message.DESCRIPTION}
        </NuiTypography>
      </Stack>
      <Box sx={{ width: '100%' }}>
        <NuiLinearProgress
          variant="determinate"
          value={progress * 100}
          sx={{
            '&.MuiLinearProgress-root.MuiLinearProgress-colorPrimary .MuiLinearProgress-bar':
              {
                backgroundColor: globals.colors.app.primary,
              },
          }}
        />
      </Box>
    </Box>
  );
};

export default Loading;
