import en from './locales/en.json';

const text: any = { en };

let locale = 'en';

const getText = (): any => {
  return text[locale];
};

export { getText };
