import { DialogContent, Stack, Box } from '@mui/material';

import { getText } from '../../helpers/textHelper';
import NuiDialog from '../NuiDialog';
import NuiTypography from '../NuiTypography';
import NuiButton from '../NuiButton';
import { globals } from '../../theme';

const id = 'intro';
const text = getText();
const dialogText = text.DIALOGS[id.toUpperCase()];

const badgeSize = 128;

const DialogIntro: React.FC<{ open: boolean; onAction: Function }> = ({
  open,
  onAction,
}) => {
  return (
    <NuiDialog
      open={open}
      onClose={() => onAction('close', id)}
      sx={{
        '.MuiDialog-paper': {
          background: globals.colors.bgBase,
        },
      }}>
      <DialogContent>
        <Box
          sx={(theme) => ({
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: `${theme.spacing(4)} 0 ${theme.spacing(8)}`,
          })}>
          <Box
            sx={{
              width: badgeSize,
              height: badgeSize,
              borderRadius: 999,
              overflow: 'hidden',
              boxShadow: `0px 4px 16px 0px ${globals.colors.black80}`,
            }}>
            <img
              src="/img/logo.png"
              alt="Logo"
              style={{
                width: '100%',
                height: 'auto',
                userSelect: 'none',
                pointerEvents: 'none',
              }}
            />
          </Box>
        </Box>
        <Stack
          direction="column"
          spacing={4}
          alignItems="center"
          justifyContent="center"
          marginBottom={6}>
          {/* Title */}
          <NuiTypography variant="title-bold" textAlign="center" width="75%">
            {dialogText.TITLE}
          </NuiTypography>

          {/* Description */}
          <NuiTypography variant="body" textAlign="center">
            {dialogText.DESCRIPTION_1}
          </NuiTypography>
          <NuiTypography variant="body" textAlign="center">
            {dialogText.DESCRIPTION_2}
          </NuiTypography>
        </Stack>
        <Stack
          direction="column"
          spacing={2}
          alignItems="center"
          justifyContent="center"
          marginBottom={2}>
          <NuiButton color="primary" onClick={() => onAction('close', id)}>
            {dialogText.CTA}
          </NuiButton>
        </Stack>
      </DialogContent>
    </NuiDialog>
  );
};

export default DialogIntro;
