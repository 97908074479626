import { useEffect, useState } from 'react';
import { Box, keyframes } from '@mui/material';
import LoadingBoombox from './LoadingBoombox';
import LoadingNote from './LoadingNote';
import LoadingKeyboard from './LoadingKeyboard';
import LoadingPlanet from './LoadingPlanet';
import LoadingVinyl from './LoadingVinyl';
import { globals } from '../../theme';

const animArtwork = () =>
  `${keyframes`
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`} 0.3s ease-in-out forwards`;

const animJiggle = (r: number) =>
  `${keyframes`
  0% {
    transform: rotate(-${r}deg);
  }
  50% {
    transform: rotate(${r}deg);
  }
  100% {
    transform: rotate(-${r}deg);
  }
`} 1s ease-in-out infinite`;

const animSpin = () =>
  `${keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`} 1.333s linear infinite`;

const animStar = (delay: number) =>
  `${keyframes`
  0% {
    opacity: 1;
    transform: scale(0);
  }
  99% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
`} 0.6s ${delay + 1000}ms steps(6) forwards`;

const animFill = (colors: string[]) =>
  `${keyframes`
  0% {
    fill: ${colors[0]};
  }
  33% {
    fill: ${colors[1]};
  }
  66% {
    fill: ${colors[2]};
  }
  100% {
    fill: ${colors[0]};
  }
`} 4s linear infinite`;

const _artwork = (id: string | null) => {
  let Comp = null;
  let animation = null;
  let colors: string[] = [];
  if (!id) return { Comp, animation, colors };
  switch (id) {
    case 'boombox':
      Comp = <LoadingBoombox />;
      animation = animJiggle(3);
      colors = [
        globals.colors.app.quaternary,
        globals.colors.app.tertiary,
        globals.colors.app.primary,
      ];
      break;
    case 'note':
      Comp = <LoadingNote />;
      animation = animJiggle(3);
      colors = [
        globals.colors.app.secondary,
        globals.colors.app.quaternary,
        globals.colors.app.secondary,
      ];
      break;
    case 'keyboard':
      Comp = <LoadingKeyboard />;
      animation = animJiggle(3);
      colors = [
        globals.colors.app.primary,
        globals.colors.app.secondary,
        globals.colors.app.tertiary,
      ];
      break;
    case 'planet':
      Comp = <LoadingPlanet />;
      animation = animJiggle(3);
      colors = [
        globals.colors.app.quaternary,
        globals.colors.app.primary,
        globals.colors.app.tertiary,
      ];
      break;
    case 'vinyl':
      Comp = <LoadingVinyl />;
      animation = animSpin();
      colors = ['#666', globals.colors.primary, globals.colors.app.tertiary];
      break;
    default:
      console.log(`Nothing for ${id}`);
      break;
  }
  return { Comp, animation, colors };
};

const LoadingArtwork: React.FC<{
  artwork: string;
}> = ({ artwork }) => {
  const [active, setActive] = useState<boolean>(true);
  const [id, setId] = useState<string | null>(null);

  // Transition on artwork change
  useEffect(() => {
    setActive(false);
    const timeout = setTimeout(() => {
      setId(artwork);
      setActive(true);
    }, 300);
    return () => clearTimeout(timeout);
  }, [artwork]);

  const { Comp, animation, colors } = _artwork(id);

  return (
    <Box
      sx={(theme) => ({
        position: 'relative',
        width: 240,
        height: 240,
        paddingBottom: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: active ? 1 : 0,
        transition: 'opacity 0.3s',
      })}>
      {Comp && (
        <>
          {/* Artwork */}
          <Box
            sx={{
              width: '100%',
              height: '100%',
              opacity: 0,
              animation: active ? animArtwork() : null,
              svg: {
                width: '100%',
                height: 'auto',
                animation,
                '.anim-fill': {
                  animation: colors ? animFill(colors) : null,
                },
              },
            }}>
            {Comp}
          </Box>

          {/* Stars */}
          {active && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                width: '50%',
                height: '50%',
                svg: {
                  '.star': {
                    fill: globals.colors.white,
                    transformBox: 'fill-box',
                    transformOrigin: 'center',
                    opacity: 0,
                    '&:nth-of-type(1)': {
                      animation: animStar(0),
                    },
                    '&:nth-of-type(2)': {
                      animation: animStar(200),
                    },
                    '&:nth-of-type(3)': {
                      animation: animStar(400),
                    },
                  },
                },
              }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 128 128"
                fill="none">
                <path
                  className="star"
                  d="M95.9818 48.1517C81.0209 48.1517 68.8876 60.3107 68.8876 75.3033C68.8876 60.3107 56.7543 48.1517 41.7935 48.1517C56.7543 48.1517 68.8876 35.9926 68.8876 21C68.8876 35.9926 81.0209 48.1517 95.9818 48.1517Z"
                />
                <path
                  className="star"
                  d="M57.4704 85.2675C49.051 85.2675 42.2352 92.1083 42.2352 100.535C42.2352 92.0977 35.4089 85.2675 27 85.2675C35.4195 85.2675 42.2352 78.4267 42.2352 70C42.2352 78.4373 49.0615 85.2675 57.4704 85.2675Z"
                />
                <path
                  className="star"
                  d="M100.452 96.2498C94.2481 96.2498 89.2259 101.283 89.2259 107.5C89.2259 101.283 84.2038 96.2498 78 96.2498C84.2038 96.2498 89.2259 91.217 89.2259 85C89.2259 91.217 94.2481 96.2498 100.452 96.2498Z"
                />
              </svg>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default LoadingArtwork;
