import { useCallback, useState } from 'react';
import { DialogContent, Stack } from '@mui/material';

import { getText } from '../../helpers/textHelper';
import NuiDialog from '../NuiDialog';
import NuiTypography from '../NuiTypography';
import NuiButton from '../NuiButton';
import NuiIcon from '../NuiIcon';
import NuiTextField from '../NuiTextField';
import { globals } from '../../theme';

const id = 'feedback';
const text = getText();
const dialogText = text.DIALOGS[id.toUpperCase()];

const Input: React.FC<{
  id: string;
  text: { INPUT_LABEL: string; INPUT_PLACEHOLDER: string };
  value: string;
  onFeedback: Function;
}> = ({ id, text, value, onFeedback }) => {
  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <NuiTypography variant="caption-demi" color={globals.colors.white}>
        {text.INPUT_LABEL}
      </NuiTypography>
      <NuiTextField
        type="text"
        placeholder={text.INPUT_PLACEHOLDER}
        value={value}
        multiline
        rows={4}
        onChange={(e: any) => onFeedback(id, e.target.value)}
      />
    </Stack>
  );
};

// Can be expanded to support more fields
const initialState = {
  feedback: '',
};

const DialogFeedback: React.FC<{
  open: boolean;
  data: PlaylistTypes;
  onAction: Function;
}> = ({ open, data, onAction }) => {
  const [feedback, setFeedback] = useState<any>(initialState);

  const handleFeedback = useCallback(
    (id: string, val: string) => {
      const newFeedback = { ...feedback, [id]: val };
      setFeedback(newFeedback);
    },
    [feedback],
  );

  const handleSend = useCallback(() => {
    // Check not empty
    if (!Object.values(feedback).some((val) => val !== '')) return;
    onAction('feedback', id, feedback);
    setFeedback(initialState);
  }, [feedback, onAction]);

  const title = data?.title;
  if (!title) return null;

  return (
    <NuiDialog
      open={open}
      onClose={() => onAction('close', id)}
      sx={{
        '.MuiDialog-paper': {
          width: '100%',
          background: globals.colors.bgBase,
        },
      }}
    >
      <DialogContent>
        <Stack
          direction="column"
          spacing={2}
          alignItems="center"
          justifyContent="center"
          marginBottom={6}
        >
          <NuiIcon
            name="playlist"
            size={16}
            color={globals.colors.app.primary}
          />
          <NuiTypography
            variant="headline-bold"
            textAlign="center"
            gutterBottom
          >
            {title}
          </NuiTypography>
        </Stack>
        <Stack
          direction="column"
          spacing={6}
          alignItems="center"
          justifyContent="center"
          marginBottom={6}
        >
          {/* Feedback */}
          <Input
            id="feedback"
            text={dialogText}
            value={feedback.feedback}
            onFeedback={handleFeedback}
          />
        </Stack>

        <Stack
          direction="column"
          spacing={4}
          alignItems="center"
          justifyContent="center"
          marginBottom={2}
        >
          <NuiButton color="primary" onClick={handleSend}>
            {dialogText.CTA}
          </NuiButton>
        </Stack>
      </DialogContent>
    </NuiDialog>
  );
};

export default DialogFeedback;
