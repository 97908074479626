import { Box, keyframes, Rating as MuiRating, Stack } from '@mui/material';

import { getText } from '../../helpers/textHelper';
import NuiIcon from '../NuiIcon';
import NuiTypography from '../NuiTypography';
import NuiLink from '../NuiLink';
import { globals } from '../../theme';

const animRating = () =>
  `${keyframes`
  0% {
    fill: ${globals.colors.app.quaternary};
  }
  33% {
    fill: ${globals.colors.app.secondary};
  }
  66% {
    fill: ${globals.colors.app.tertiary};
  }
  100% {
    fill: ${globals.colors.app.primary};
  }
`} 0.6s linear forwards`;

const animFeedback = () =>
  `${keyframes`
  0% {
    opacity: 0;
    transform: translateY(-0.5rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`} 0.3s 0.1s forwards`;

interface RatingProps {
  state: string;
  rating: number;
  onAction: Function;
}

const text = getText();
const ratingText = text.RATING;

const Rating: React.FC<RatingProps> = ({ state, rating, onAction }) => {
  const isActive = state === 'playlist' || state === 'saved';
  const isFeedback = rating > 0;

  return (
    <Stack direction="column" spacing={1} paddingTop={1}>
      {/* Title */}
      <NuiTypography
        variant="caption-smallest-bold"
        color={globals.colors.white40}
        sx={{
          textAlign: 'center',
          textTransform: 'uppercase',
          letterSpacing: 0.5,
        }}
      >
        {ratingText.TITLE}
      </NuiTypography>

      {/* Rating */}
      <Stack direction="column" spacing={2}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <MuiRating
            name="customized-color"
            defaultValue={0}
            value={rating}
            precision={1}
            disabled={!isActive}
            getLabelText={(value: number) =>
              `${value} Heart${value !== 1 ? 's' : ''}`
            }
            icon={
              <NuiIcon
                name="star-active"
                size={8}
                color={globals.colors.app.primary}
              />
            }
            emptyIcon={
              <NuiIcon name="star" size={8} color={globals.colors.white20} />
            }
            sx={{
              '.MuiRating-iconFilled': {
                svg: {
                  animation: animRating(),
                },
              },
            }}
            onChange={(e: any) => {
              onAction &&
                onAction('rating', { rating: parseInt(e.target.value) });
            }}
          />
        </Box>

        {/* Feedback */}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            overflow: 'hidden',
            maxHeight: isFeedback ? 20 : 0,
            transition: 'max-height 0.3s',
          }}
        >
          <NuiTypography
            variant="caption"
            sx={{
              textAlign: 'center',
              opacity: 0,
              animation: isFeedback ? animFeedback() : null,
            }}
          >
            {ratingText.CONFIRMATION_1}
            <NuiLink
              variant="caption-demi"
              onClick={() => onAction && onAction('feedback')}
              sx={{ cursor: 'pointer' }}
            >
              {ratingText.CONFIRMATION_2}
            </NuiLink>
            {ratingText.CONFIRMATION_3}
          </NuiTypography>
        </Box>
      </Stack>
    </Stack>
  );
};

export default Rating;
