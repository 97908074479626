const white = '252, 252, 252';
const black = '19, 19, 19';

const globals: any = {
  colors: {
    // Colours
    primary: '#2259FF',
    secondaryCyan: '#68ddff',
    secondaryLime: '#daec31',
    secondaryLavender: '#eab8ff',
    secondaryBeige: '#f4ddcb',
    secondaryOrange: '#ffca7d',
    secondaryRed: '#ffabab',
    semanticSuccessDark: '#2e7d32',
    semanticSuccessLight: '#47cd6e',
    semanticWarningDark: '#c74c17',
    semanticWarningLight: '#ff9246',
    semanticErrorDark: '#d31d1d',
    semanticErrorLight: '#ff6360',
    bgLight: '#3d4158',
    bgBase: '#181d38',
    bgDark: '#13172d',
    bgDarkest: '#151722',
    // Monos
    white: `rgb(${white})`,
    white90: `rgba(${white}, 0.9)`,
    white80: `rgba(${white}, 0.8)`,
    white70: `rgba(${white}, 0.7)`,
    white60: `rgba(${white}, 0.6)`,
    white50: `rgba(${white}, 0.5)`,
    white40: `rgba(${white}, 0.4)`,
    white30: `rgba(${white}, 0.3)`,
    white20: `rgba(${white}, 0.2)`,
    white10: `rgba(${white}, 0.1)`,
    white5: `rgba(${white}, 0.05)`,
    white0: `rgba(${white}, 0)`,
    black: `rgb(${black})`,
    black90: `rgba(${black}, 0.9)`,
    black80: `rgba(${black}, 0.8)`,
    black70: `rgba(${black}, 0.7)`,
    black60: `rgba(${black}, 0.6)`,
    black50: `rgba(${black}, 0.5)`,
    black40: `rgba(${black}, 0.4)`,
    black30: `rgba(${black}, 0.3)`,
    black20: `rgba(${black}, 0.2)`,
    black10: `rgba(${black}, 0.1)`,
    black5: `rgba(${black}, 0.05)`,
    black0: `rgba(${black}, 0)`,
    // App theme
    app: {
      primary: '#00FFFF',
      secondary: '#FD81F5',
      tertiary: '#00F1A3',
      quaternary: '#FF813A',
    },
  },
  // Units
  units: (factor: number) => `${0.25 * factor}rem`,
  unitsPx: (factor: number) => `${4 * factor}px`,
};

export default globals;
